<template>
  <div class="news">
    <VideoTitle title="消息"></VideoTitle>
    <div v-if="listInfo.length" class="news_conter">
      <ul>
        <li v-for="item in listInfo" :key="item.id">
          <span>{{ item.title + ' ' + item.createTime }}</span>
          <div>{{ item.content }}</div>
        </li>
      </ul>
    </div>
    <el-empty v-else :image-size="200" description="暂无数据"></el-empty>
    <Pagination v-if="listQurey.total" :pageTabel="listQurey" @pagingChange="pagingChange"></Pagination>
  </div>
</template>

<script>
import VideoTitle from '@/components/public/VideoTitle.vue';
import { getSystem } from '@/utils/serveApi';
import Pagination from '@/components/public/Pagination.vue';
export default {
  name: 'news',
  data() {
    return {
      listInfo: [],
      listQurey: {
        pageNum: 1,
        pageSize: 6,
        total: 0,
        userId: this.$store.getters.userData.userId
      }
    };
  },
  created() {
    this.getlistData();
  },
  methods: {
    getlistData() {
      getSystem(this.listQurey).then(res => {
        const { totalCount, list } = res.data;
        res.success ? (this.listInfo = list) : this.$message.error(res.stateDesc);
        this.listQurey.total = totalCount;
      });
    },
    pagingChange(o) {
      this.listQurey.pageNum = o.pageNum;
      this.getlistData();
    }
  },
  components: { VideoTitle, Pagination }
};
</script>
<style lang="scss" scoped>
.news {
  &_conter {
    padding: 30px 40px;
    ul {
      li {
        margin-bottom: 16px;
        & > span {
          font-size: 18px;
          color: #999999;
        }
        & > div {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
